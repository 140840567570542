type Listener = (...args: any[]) => void;

export class EventEmitter {
    private events: { [key: string]: Listener[] };

    constructor() {
        this.events = {};
    }

    // Método para suscribir a un evento
    on(event: string, listener: Listener): void {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event].push(listener);
    }

    // Método para emitir un evento
    emit(event: string, ...args: any[]): void {
        if (this.events[event]) {
            this.events[event].forEach(listener => listener(...args));  // Llamamos a cada listener con los argumentos proporcionados
        }
    }

    // Método para remover un listener de un evento
    off(event: string, listener: Listener): void {
        if (this.events[event]) {
            this.events[event] = this.events[event].filter(l => l !== listener);  // Filtra los listeners que no sean el que queremos eliminar
        }
    }

    // Método para escuchar un evento una sola vez
    once(event: string, listener: Listener): void {
        const wrapper = (...args: any[]) => {
            listener(...args);
            this.off(event, wrapper);
        };
        this.on(event, wrapper);
    }
}

export default EventEmitter;
