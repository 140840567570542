import EventEmitter from "../utils/EventEmitter";

export class InputManager extends EventEmitter {

    keysPressed: Set<string>;

    constructor() {

        super();

        this.keysPressed = new Set();

        this.createEventListeners();

    }

    createEventListeners() {
        window.addEventListener("keydown", (e) => {
            this.keysPressed.add(e.key);
            this.emit("keydown", e.key);
        });
        window.addEventListener("keyup", (e) => {
            this.keysPressed.delete(e.key);
            this.emit("keyup", e.key);
        });
        window.addEventListener('wheel', (e) => {
            if (e.deltaY > 0) this.emit("zoom", "out");
            else this.emit("zoom", "in");
        });
    }

}