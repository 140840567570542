export class PRNG {
    
    MT: Uint32Array;
    index: number;

    constructor(seed: number) {
        this.MT = new Uint32Array(624);
        this.index = 0;
        this.initialize(seed);
    }

    initialize(seed: number) {
        this.MT[0] = seed >>> 0;
        for (let i = 1; i < 624; i++) {
            this.MT[i] = (1812433253 * (this.MT[i - 1] ^ (this.MT[i - 1] >>> 30)) + i) >>> 0;
        }
    }

    extractNumber() {
        if (this.index === 624) {
            this.twist();
        }
        let y = this.MT[this.index++];
        y ^= (y >>> 11);
        y ^= (y << 7) & 2636928640;
        y ^= (y << 15) & 4022730752;
        y ^= (y >>> 18);
        return y >>> 0;
    }

    twist() {
        for (let i = 0; i < 624; i++) {
            let y = (this.MT[i] & 0x80000000) | (this.MT[(i + 1) % 624] & 0x7fffffff);
            this.MT[i] = this.MT[(i + 397) % 624] ^ (y >>> 1);
            if (y % 2 !== 0) {
                this.MT[i] ^= 2567483615;
            }
        }
        this.index = 0;
    }

    random() {
        return this.extractNumber() / 0xFFFFFFFF; // Normaliza a [0, 1)
    }
}