export class CameraController {
    x: bigint;
    y: bigint;
    fractionalX: number;
    fractionalY: number;

    zoom: number;
    zoomStep: number;
    zoomMin: number;
    zoomMax: number;

    constructor() {
        this.x = BigInt(0);
        this.y = BigInt(0);
        this.fractionalX = 0;
        this.fractionalY = 0;

        this.zoom = 1;
        this.zoomStep = 1.1;
        this.zoomMin = 0.75;
        this.zoomMax = 100;

        this.initializeEventListeners();
    }

    private isDragging = false;
    private startX = 0;
    private startY = 0;
    private lastX = 0;
    private lastY = 0;
    private lastDistance: number = 0;

    initializeEventListeners() {
        window.addEventListener('touchmove', this.handleTouchMove.bind(this), { passive: false });
        window.addEventListener('touchstart', this.handleTouchStart.bind(this));
        window.addEventListener('touchend', this.handleTouchEnd.bind(this));

        window.addEventListener('mousedown', this.handleMouseDown.bind(this));
        window.addEventListener('wheel', this.handleWheel.bind(this));
    }

    handleTouchMove(event: TouchEvent) {
        event.preventDefault();

        if (event.touches.length === 1) {
            if (!this.isDragging) return;
            const touch = event.touches[0];
            const deltaX = (touch.clientX - this.startX) / this.zoom * this.zoom;
            const deltaY = (touch.clientY - this.startY) / this.zoom * this.zoom;

            this.updatePosition(-deltaX, -deltaY);
            this.startX = touch.clientX;
            this.startY = touch.clientY;
        } else if (event.touches.length === 2) {
            const distance = this.getDistance(event.touches);
            if (this.lastDistance === 0) {
                this.lastDistance = distance; // Guardar la distancia inicial
            } else {
                const deltaDistance = distance - this.lastDistance;
                this.zoomCamera(deltaDistance, event.touches[0].clientX, event.touches[0].clientY);
            }
        }
    }

    handleTouchEnd(event: TouchEvent) {
        if (event.touches.length < 2) {
            this.lastDistance = 0; // Resetea la última distancia si hay menos de 2 toques
        }
    }

    getDistance(touches: TouchList): number {
        const dx = touches[0].clientX - touches[1].clientX;
        const dy = touches[0].clientY - touches[1].clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    handleMouseDown(event: MouseEvent) {
        this.isDragging = true;
        this.startX = event.clientX;
        this.startY = event.clientY;
        this.lastX = this.fractionalX;
        this.lastY = this.fractionalY;
        window.addEventListener('mousemove', this.handleMouseMove.bind(this));
        window.addEventListener('mouseup', this.handleMouseUp.bind(this));
    }

    handleMouseMove(event: MouseEvent) {
        if (!this.isDragging) return;

        const deltaX = (event.clientX - this.startX) / this.zoom * this.zoom;
        const deltaY = (event.clientY - this.startY) / this.zoom * this.zoom;

        this.updatePosition(-deltaX, -deltaY);
        this.startX = event.clientX;
        this.startY = event.clientY;
    }

    handleMouseUp() {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.handleMouseMove.bind(this));
        window.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    }

    handleTouchStart(event: TouchEvent) {
        if (event.touches.length === 1) {
            this.isDragging = true;
            const touch = event.touches[0];
            this.startX = touch.clientX;
            this.startY = touch.clientY;
        } else if (event.touches.length === 2) {
            this.lastDistance = this.getDistance(event.touches); // Guardar distancia inicial al iniciar el gesto de pellizco
        }
    }

    handleWheel(event: WheelEvent) {
        const deltaZoom = event.deltaY * 0.01;
        const mouseX = event.clientX;
        const mouseY = event.clientY;

        this.zoomCamera(deltaZoom, mouseX, mouseY);
    }

    zoomCamera(deltaZoom: number, mouseX: number, mouseY: number) {
        const oldZoom = this.zoom;

        if (deltaZoom < 0) this.zoom *= this.zoomStep / Math.abs(deltaZoom);
        else this.zoom /= this.zoomStep / Math.abs(deltaZoom);

        this.zoom = Math.min(this.zoom, this.zoomMax);
        this.zoom = Math.max(this.zoom, this.zoomMin);

        const zoomFactor = this.zoom / oldZoom;

        const deltaX = -(mouseX - window.innerWidth / 2);
        const deltaY = -(mouseY - window.innerHeight / 2);

        this.updatePosition(deltaX * (1 - zoomFactor), deltaY * (1 - zoomFactor));
    }

    updatePosition(deltaX: number, deltaY: number) {
        const scaledDeltaX = deltaX / this.zoom;
        const scaledDeltaY = deltaY / this.zoom;

        this.fractionalX += scaledDeltaX;
        this.fractionalY -= scaledDeltaY;

        const intX = Math.floor(this.fractionalX);
        const intY = Math.floor(this.fractionalY);

        this.x += BigInt(intX);
        this.y += BigInt(intY);

        this.fractionalX -= intX;
        this.fractionalY -= intY;
    }
}
