import { IdCalculator } from "../utils/IdCalculator";
import { Point } from "./Point";

export class Chunk {

    id: bigint;

    x: bigint;
    y: bigint;
    width: number;

    points: Int16Array;

    constructor(x: bigint, y: bigint, width: number, density: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.points = new Int16Array(density);
        this.id = IdCalculator.getId(Number(x), Number(y));
    }

    xRelativeToCamera(cameraX: bigint): number {
        return Number((this.x * BigInt(this.width)) - cameraX);
    }

    yRelativeToCamera(cameraY: bigint): number {
        return Number((this.y * BigInt(this.width)) - cameraY);
    }

    havePoint(x: bigint, y: bigint): boolean {
        return this.x === (x / BigInt(this.width)) && this.y === (y / BigInt(this.width));
    }
    
    get xGlobal(): bigint {
        return this.x * BigInt(this.width);
    }

    get yGlobal(): bigint {
        return this.y * BigInt(this.width);
    }

    get coordsAsString(): string {
        return `${this.x},${this.y}`;
    }

}