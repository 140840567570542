import React, { useEffect, useRef } from 'react';
import { Engine } from './wordot/src/engine/Engine'; // Importar la librería que creaste
import { Chunk } from './wordot/src/world/Chunk';
import { ChunkGenerator } from './wordot/src/world/ChunkGenerator';
import { GraphicsHelper } from './wordot/src/graphics/GraphicsHelper';
import { GraphicsManager } from './wordot/src/graphics/GraphicManager';
import Camera from './wordot/src/camera/Camera';
import { WordConverter } from './wordot/src/utils/WordConverter';

const App: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    let delta = 0;
    let lastTime = 0;
    let engine: Engine;
    
    const renderLoop = () => {
      if (!engine.world.chunks || !engine.camera) return; // Asegúrate de que engine esté listo

      // Calcular delta
      delta = (performance.now() - lastTime) / 1000;
      lastTime = performance.now();

      // Actualizar y renderizar
      engine.update(delta);
      engine.render();

      // Solicitar el próximo frame
      requestAnimationFrame(renderLoop);
    };

    if (canvasRef.current) {
      engine = new Engine(canvasRef.current);
      engine.world.chunkGenerator = new ChunkGenerator(50, 10, 12345);
      engine.camera.zoom = 10;
      engine.camera.x = 0n;
      engine.camera.y = 0n;
      canvasRef.current.width = window.innerWidth;
      canvasRef.current.height = window.innerHeight;

      // Iniciar el bucle de animación
      lastTime = performance.now();
      requestAnimationFrame(renderLoop);
    }
  }, []);

  /*useEffect(() => {
    const chunkGenerator = new ChunkGenerator({chunkWidth: 5});
    const graphics = new GraphicsManager(canvasRef.current!);
    const chunk = chunkGenerator.generateChunk(BigInt(20), BigInt(0));
    graphics.loadChunks([chunk], BigInt(0), BigInt(0), 1);
    graphics.draw(BigInt(0), BigInt(0), new Map<bigint, Chunk>([[BigInt(0), chunk]]), new Camera());
  }, []);*/

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width: '100%',
      backgroundColor: '#1c1c1c',
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    }}>
      <canvas ref={canvasRef} width={800} height={800}></canvas>
    </div>
  );
};

export default App;
