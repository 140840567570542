interface Esquina {
    numEsquina: number;
    qEsquina: number;
    rEsquina: number;
}

export class IdCalculator {
    // Tipo de retorno para la función obtenerEsquina


    // Obtener las coordenadas del hexágono esquina en la capa correspondiente
    // Esto es, busca en qué rayo (lado) cae el hexágono q,r y cuál es la esquina correspondiente
    static obtenerEsquina(q: number, r: number, capa: number): Esquina | undefined {
        const esquinas: [number, number][] = [
            [0, -capa],           // Esquina 1
            [capa, -capa],        // Esquina 2
            [capa, 0],            // Esquina 3
            [0, capa],            // Esquina 4
            [-capa, capa],        // Esquina 5
            [-capa, 0]            // Esquina 6
        ];

        // Si el hexágono está exactamente en una de las esquinas
        for (let i = 0; i < 6; i++) {
            if (esquinas[i][0] === q && esquinas[i][1] === r) {
                return { numEsquina: i, qEsquina: q, rEsquina: r };
            }
        }

        // Para cada lado, busca el rayo al que pertenece q, r
        for (let i = 0; i < 6; i++) {
            const inicio = esquinas[i];
            const fin = esquinas[(i + 1) % 6];

            // Si el hexágono q,r está alineado entre dos esquinas (en el rayo del lado)
            const vectorLado = [fin[0] - inicio[0], fin[1] - inicio[1]];

            // Busca si el hexágono q, r cae en el rayo que va de una esquina a otra
            const difQ = q - inicio[0];
            const difR = r - inicio[1];
            const t = Math.max(Math.abs(difQ / (vectorLado[0] || 1)), Math.abs(difR / (vectorLado[1] || 1)));

            if (t >= 0 && t <= 1) {
                return { numEsquina: i, qEsquina: inicio[0], rEsquina: inicio[1] };
            }
        }
    }

    static totalUntilLayer(capa: number): number {
        if (capa === 0) return 0;  // Capa 0 tiene 1 hexágono (el central)
        return 1 + 3 * capa * (capa - 1);  // Fórmula para hexágonos hasta la capa actual
    }
    static distanciaAxial(q1: number, r1: number, q2: number, r2: number): number {
        const deltaQ = q2 - q1;
        const deltaR = r2 - r1;
        const deltaS = -(deltaQ + deltaR); // s = -q - r en coordenadas axiales

        // La distancia es el máximo de los valores absolutos de deltaQ, deltaR y deltaS
        return Math.max(Math.abs(deltaQ), Math.abs(deltaR), Math.abs(deltaS));
    }

    // Función principal que calcula la ID global de las coordenadas de un hexagono en un grid de hexágonos
    static getId(q: number, r: number): bigint {

        // 1. Obtener capa del hexágono
        const capa = Math.max(Math.abs(q), Math.abs(r), Math.abs(-q - r));

        // 2. Obtener la esquina correspondiente en la capa
        const esquina = IdCalculator.obtenerEsquina(q, r, capa);
        if (!esquina)  throw new Error('Esquina no encontrada');
        const { numEsquina, qEsquina, rEsquina } = esquina;

        // 3. Calcular la ID del hexágono esquina en la capa
        const idHexagonoEsquina = 1 + numEsquina * capa;

        // 4. Calcular la distancia al hexágono esquina más cercano
        const distanciaAlHexagonoEsquina = IdCalculator.distanciaAxial(q, r, qEsquina, rEsquina);

        // 5. Obtener la ID local de la capa del hexágono
        const idEnCapa = idHexagonoEsquina + distanciaAlHexagonoEsquina;

        // 6. Calcular la ID global del hexágono
        const idGlobal = IdCalculator.totalUntilLayer(capa) + idEnCapa;

        return BigInt(idGlobal);
    }

}