import { PRNG } from "../utils/PRNG";
import { Chunk } from "./Chunk";
import { Point } from "./Point";

export class ChunkGenerator {

    chunkWidth: number;
    density: number;
    seed: number;

    allPosiblePointsOfChunkPreGenerated: Int16Array;

    constructor(chunkWidth: number, density: number, seed: number) {

        this.chunkWidth = chunkWidth;
        this.density = density;
        this.seed = seed;

        this.allPosiblePointsOfChunkPreGenerated = new Int16Array(this.density);

        this.generateAllPosiblePointsOfChunk();
    }

    generateAllPosiblePointsOfChunk(): void {
        const offSet: number = Math.floor(this.chunkWidth / 2);
        const totalPoints = (2 * offSet + 1) ** 2;  // Total de puntos dentro del chunk
        this.allPosiblePointsOfChunkPreGenerated = new Int16Array(totalPoints * 2);  // Almacenamos pares de coordenadas [x, y]

        let index = 0;
        for (let i = -offSet; i <= offSet; i++) {
            for (let j = -offSet; j <= offSet; j++) {
                // Guardar las coordenadas en el TypedArray
                this.allPosiblePointsOfChunkPreGenerated[index] = i;       // Coordenada X
                this.allPosiblePointsOfChunkPreGenerated[index + 1] = j;   // Coordenada Y
                index += 2;  // Avanzamos 2 posiciones para el siguiente punto
            }
        }
    }

    generateChunk(x: bigint, y: bigint): Chunk {
        const chunk = new Chunk(x, y, this.chunkWidth, this.density);
        chunk.points = this.generateChunkPoints(chunk.id);
        return chunk;
    }

    generateChunkPoints(id: bigint): Int16Array {
        const prng = new PRNG(this.seed + Number(id));
        const totalPointsToGenerate = this.density;
        const totalPossiblePoints = this.allPosiblePointsOfChunkPreGenerated.length / 2;
    
        const points = new Int16Array(totalPointsToGenerate * 2);
        const usedIndices = new Set<number>();
    
        let generatedPoints = 0;
    
        while (generatedPoints < totalPointsToGenerate && usedIndices.size < totalPossiblePoints) {
            const randomIndex = Math.floor(prng.random() * totalPossiblePoints);
            
            if (!usedIndices.has(randomIndex)) {
                points[generatedPoints * 2] = this.allPosiblePointsOfChunkPreGenerated[randomIndex * 2];
                points[generatedPoints * 2 + 1] = this.allPosiblePointsOfChunkPreGenerated[randomIndex * 2 + 1];
                
                usedIndices.add(randomIndex);
                generatedPoints++;
            }
        }
    
        return points;
    }
    
    
    
    




    getCenterOfChunksInArea(x: bigint, y: bigint, areaWidth: number, areaHeight: number): { x: bigint, y: bigint }[] {
        
        const offSetX: number = Math.floor(Math.min(areaWidth, 1000) / this.chunkWidth / 2) + 2;
        const offSetY: number = Math.floor(Math.min(areaHeight, 1000) / this.chunkWidth / 2) + 2;

        const centers: { x: bigint, y: bigint }[] = [];
        const { x: xCenter, y: yCenter } = this.getChunkCenterThatContainsPoint(x, y);

        for (let i = -offSetX; i <= offSetX; i++) {
            for (let j = -offSetY; j <= offSetY; j++) {
                centers.push({
                    x: BigInt(i) + xCenter,
                    y: BigInt(j) + yCenter
                });
            }
        }

        return centers;

    }

    getChunkCenterThatContainsPoint(x: bigint, y: bigint): { x: bigint, y: bigint } {
        return {
            x: x / BigInt(this.chunkWidth),
            y: y / BigInt(this.chunkWidth)
        };
    }

}