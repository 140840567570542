import { Camera } from "../camera/Camera";
import { CameraController } from "../camera/CameraController";
import { GraphicsManager } from "../graphics/GraphicManager";
import { Chunk } from "../world/Chunk";
import { World } from "../world/World";
import { InputManager } from "./InputManager";

export class Engine {

    graphics: GraphicsManager;
    world: World;
    camera: CameraController;
    inputManager: InputManager;

    constructor(canvas: HTMLCanvasElement) {

        this.graphics = new GraphicsManager(canvas);
        this.world = new World();
        this.camera = new CameraController();
        this.inputManager = new InputManager();

        this.subscribeToWorldEvents();

    }

    update(delta: number): void {
        //this.camera.update(delta, this.inputManager.keysPressed);
        this.world.loadChunks(this.camera.x, this.camera.y, this.graphics.canvas.width / this.camera.zoom, this.graphics.canvas.height / this.camera.zoom);
    }

    render() {
        this.graphics.draw(this.camera.x, this.camera.y, this.world.chunks, this.camera.x, this.camera.y, this.camera.zoom, this.camera.fractionalX, this.camera.fractionalY);
    }

    subscribeToWorldEvents() {
        this.world.on('chunksLoaded', (loadedChunks: Chunk[]) => {
            this.graphics.loadChunks(loadedChunks, this.camera.x, this.camera.y);
        });
        this.world.on('chunksUnloaded', (unloadedChunks: Chunk[]) => {
            this.graphics.unloadChunks(unloadedChunks);
        });
    }

}