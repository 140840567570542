import { Camera } from "../camera/Camera";
import { WordConverter } from "../utils/WordConverter";
import { Chunk } from "../world/Chunk";
import { GraphicsHelper } from "./GraphicsHelper";

export class GraphicsManager {

    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D | null;

    loadedChunks: Map<bigint, HTMLCanvasElement> = new Map();

    constructor(canvas: HTMLCanvasElement) {
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');
    }

    async loadChunks(chunksToLoad: Chunk[], cameraX: bigint, cameraY: bigint) {

        const chunkArray = Array.from(chunksToLoad);

        chunkArray.sort((a: Chunk, b: Chunk) => {
            const distA = Math.sqrt(Math.pow(a.xRelativeToCamera(cameraX), 2) + Math.pow(a.yRelativeToCamera(cameraY), 2));
            const distB = Math.sqrt(Math.pow(b.xRelativeToCamera(cameraX), 2) + Math.pow(b.yRelativeToCamera(cameraY), 2));
            return distA - distB;
        });

        // Llamar al método asíncrono de carga en lotes usando requestAnimationFrame
        await this.loadChunksInBatches(chunkArray, 10); // Cargar 10 chunks por cuadro

    }

    // Método que trocea la carga de los chunks en pequeños lotes por frame
    async loadChunksInBatches(chunks: Chunk[], chunkWidth: number) {
        for (let i = 0; i < chunks.length; i += chunkWidth) {
            const chunkBatch = chunks.slice(i, i + chunkWidth);

            // Cargar el lote y esperar a que termine en el siguiente cuadro de animación
            await this.loadChunkBatch(chunkBatch);

            // Esperar el siguiente frame para continuar
            await this.waitForNextFrame();
        }
    }

    // Cargar un lote de chunks
    async loadChunkBatch(chunkBatch: Chunk[]) {
        for (const chunk of chunkBatch) {
            const offscreenCanvas: HTMLCanvasElement = document.createElement('canvas');
            offscreenCanvas.width = chunk.width;  // Ajustar según el tamaño del chunk
            offscreenCanvas.height = chunk.width;  // Ajustar según el tamaño del chunk
            const offscreenCtx = offscreenCanvas.getContext('2d');

            if (offscreenCtx === null) {
                return;
            }

            // Limpiar el canvas antes de dibujar
            offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

            // Dibujar el chunk en el canvas off-screen
            this.drawChunk(offscreenCtx, chunk);

            // Almacenar la imagen del chunk
            this.loadedChunks.set(chunk.id, offscreenCanvas);
        }
    }

    drawChunk(ctx: CanvasRenderingContext2D, chunk: Chunk) {

        // Calcular el centro del canvas
        const centerX = ctx.canvas.width / 2;
        const centerY = ctx.canvas.height / 2;

        // Dibujar puntos del chunk, centrados en el canvas
        for (let i = 0; i < chunk.points.length; i += 2) {
            ctx.fillStyle = 'white';
            ctx.beginPath();

            // Ajustar las coordenadas del punto
            let pointX = centerX + chunk.points[i];       // Coordenada X (index i)
            let pointY = centerY - chunk.points[i + 1];   // Coordenada Y (index i+1)

            // Dibujar el punto
            ctx.arc(pointX, pointY, 0.5, 0, 2 * Math.PI);
            ctx.fill();
        }

        // Draw chunk square chunk area
        //ctx.strokeStyle = 'white';
        //ctx.globalAlpha = 0.5;
        //ctx.beginPath();
        //ctx.rect(centerX - chunk.size, centerY - chunk.size, chunk.size * 2, chunk.size * 2);
        //ctx.stroke();

        // Draw centered text in chunk center with chunk.id
        //ctx.fillStyle = 'white';
        //ctx.font = '30px Arial';
        // Center text
        //ctx.textAlign = 'center';
        //ctx.textBaseline = 'middle';
        //ctx.fillText(chunk.id.toString(), centerX, centerY);


    }

    draw(x: bigint, y: bigint, chunks: Map<string, Chunk>, cx: bigint, cy: bigint, zoom: number, fractionalX: number, fractionalY: number) {

        if (this.ctx === null) {
            return;
        }

        // Limpiar canvas principal
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        this.ctx.save();

        // El centro del canvas
        const centerX = this.canvas.width / 2;
        const centerY = this.canvas.height / 2;

        // Aplicar el zoom y la traslación inversa con movimiento invertido
        this.ctx.scale(zoom, zoom);

        // Dibujar los meshes de los chunks pre-renderizados
        chunks.forEach((chunk: Chunk) => {

            if (this.ctx === null) {
                return;
            }

            if (zoom < 1) {
                const offscreenCanvas = this.loadedChunks.get(chunk.id);
                if (offscreenCanvas) {

                    // Dibujar la imagen del chunk en su posición correcta ajustada por el zoom y la cámara
                    const realX = Number(chunk.xGlobal - cx) - fractionalX + (centerX / zoom - (offscreenCanvas.width / 2));
                    const realY = -Number(chunk.yGlobal - cy) + fractionalY + (centerY / zoom - (offscreenCanvas.width / 2));

                    this.ctx.drawImage(offscreenCanvas, Number(realX), Number(realY), offscreenCanvas.width, offscreenCanvas.height);

                    // Draw chunk square chunk area
                    //this.ctx.strokeStyle = 'blue';
                    //this.ctx.globalAlpha = 0.5;
                    //this.ctx.beginPath();
                    //this.ctx.rect(realX - chunk.width, realY - chunk.width, chunk.width * 2, chunk.width * 2);
                    //this.ctx.stroke();
                    //this.ctx.globalAlpha = Number(chunk.id) / 1000;

                }
            } else {
                for (let i = 0; i < chunk.points.length; i += 2) {
                    
                    if (this.ctx === null) {
                        return;
                    }

                    this.ctx.fillStyle = 'white';
                    this.ctx.beginPath();

                    // Posición el punto relativa al centro del chunk

                    // Posición del punto respecto 0,0
                    
                    let pointX: bigint | number = GraphicsHelper.getChunkPointInGlobalCoordinates(chunk.points[i], chunk.x, chunk.width);
                    let pointY: bigint | number = GraphicsHelper.getChunkPointInGlobalCoordinates(chunk.points[i + 1], chunk.y, chunk.width);

                    
                    // Posición del punto respecto a la cámara
                    pointX = GraphicsHelper.getPointRelativeToPoint(pointX, cx);
                    pointY = GraphicsHelper.getPointRelativeToPoint(pointY, cy);
                    
                    let auxx = chunk.points[i];
                    let auxy = chunk.points[i + 1];
                    // Ajustar la fracción decimal de la cámara
                    pointX -= fractionalX;
                    pointY -= fractionalY;

                    // Ajustar la posición del punto al centro del canvas
                    pointX += (centerX / zoom);
                    pointY = centerY / zoom - pointY;
                    
                    this.ctx.arc(Number(pointX), Number(pointY), 0.5, 0, 2 * Math.PI);

                    this.ctx.fill();

                    // Text
                    //this.ctx.fillStyle = 'white';
                   // this.ctx.font = '1px Arial';
                    // Center text
                    //this.ctx.textAlign = 'center';
                    //this.ctx.textBaseline = 'middle';
                    
                    //this.ctx.fillText(WordConverter.idToString(chunk.id * 100n + BigInt(i)), Number(pointX), Number(pointY));

                    // Draw point in the camera center
                    //this.ctx.fillStyle = 'green';
                    //this.ctx.beginPath();
                    //this.ctx.arc(x, -y, 0.1, 0, 2 * Math.PI);
                    //this.ctx.fill();

                }

                // Draw chunk square chunk area
                //this.ctx.strokeStyle = 'blue';
                //this.ctx.globalAlpha = 0.5;
                //this.ctx.beginPath();
                //this.ctx.rect(centerX - chunk.width, centerY - chunk.width, chunk.width * 2, chunk.width * 2);
                //this.ctx.stroke();
                //this.ctx.globalAlpha = 1;


            }
        });
        // Draw point in the center of the canvas
        //const centerOfTheCanvasX = this.canvas.width / 2;
        //const centerOfTheCanvasY = this.canvas.height / 2;
        //this.ctx.fillStyle = 'red';
        //this.ctx.beginPath();
        //this.ctx.arc(centerOfTheCanvasX / zoom, centerOfTheCanvasY / zoom, 1, 0, 2 * Math.PI);
        //this.ctx.fill();

        this.ctx.restore();
    }

    // Método para esperar al siguiente frame usando requestAnimationFrame
    waitForNextFrame() {
        return new Promise((resolve) => {
            requestAnimationFrame(resolve);
        });
    }

    unloadChunks(chunkToUnload: Chunk[]) {
        for (const chunk of chunkToUnload) {
            this.loadedChunks.delete(chunk.id);
        }
    }

}