export class GraphicsHelper {

    static getPointRelativeToPoint(p: bigint, rp: bigint): number {
        return (Number(p - rp));
    }

    static getChunkPointInGlobalCoordinates(p: number, rp: bigint, chunkWidth: number): bigint {
        return BigInt(p) + rp * BigInt(chunkWidth);
    }

}