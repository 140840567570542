import EventEmitter from "../utils/EventEmitter";
import { Chunk } from "./Chunk";
import { ChunkGenerator } from "./ChunkGenerator";

interface LastWorldChunkGenerationInfo {
    x: bigint;
    y: bigint;
    viewportWidth: number;
    viewportHeight: number;
}

export class World extends EventEmitter {

    chunkGenerator: ChunkGenerator;

    chunks: Map<string, Chunk> = new Map();

    currentChunk: Chunk | null | undefined = null;

    lastGenerationInfo: LastWorldChunkGenerationInfo | null = null;

    constructor() {
        super();
        this.chunkGenerator = new ChunkGenerator(100, 25, 12345);
    }

    loadChunks(x: bigint, y: bigint, viewportWidth: number, viewportHeight: number): void {

        function getChunkCenterThatContainsPoint(x: bigint, y: bigint, chunkWidth: number): { x: bigint, y: bigint } {
            return {
                x: BigInt(x / BigInt(chunkWidth)),
                y: BigInt(y / BigInt(chunkWidth))
            }
        }

        if (this.currentChunk && this.currentChunk.havePoint(x, y) && this.lastGenerationInfo && this.lastGenerationInfo.viewportWidth === viewportWidth && this.lastGenerationInfo.viewportHeight === viewportHeight) {
            return;
        }
        
        // Get the centers of the chunks in the area
        const centers = this.chunkGenerator.getCenterOfChunksInArea(x, y, viewportWidth, viewportHeight);

        // Get the chunk that contains the player
        const currentChunkCenter = getChunkCenterThatContainsPoint(x, y, this.chunkGenerator.chunkWidth);

        // Get the centers of the chunks to load, skipping the ones that are already loaded
        const centersOfChunksToLoad = centers.filter(center => !this.chunks.has(`${center.x},${center.y}`));

        // Generate the chunks
        let totalTime = 0;
        const numChunks = centers.length;

        for (const center of centers) {
            const startTime = performance.now();

            const chunk = this.chunkGenerator.generateChunk(center.x, center.y); //
            this.chunks.set(chunk.coordsAsString, chunk); //

            const endTime = performance.now();
            totalTime += endTime - startTime;
        }

        const averageTime = totalTime / numChunks;
        //console.log(`Tiempo medio en generar cada chunk: ${averageTime.toFixed(5)} ms`);

        this.currentChunk = this.chunks.get(`${currentChunkCenter.x},${currentChunkCenter.y}`);

        // Load the chunks
        const chunksToLoad = centersOfChunksToLoad.map(center => this.chunks.get(`${center.x},${center.y}`));
        this.emit('chunksLoaded', chunksToLoad);

        // Comparar con los chunks que ya están cargados con los que se van a cargar y
        // sacar los que ya no se necesitan
        const chunksToUnload = Array.from(this.chunks.values()).filter(chunk => {
            return !centers.some(center => center.x === chunk.x && center.y === chunk.y);
        });

        // Descargar los chunks
        for (const chunk of chunksToUnload) {
            this.chunks.delete(chunk.coordsAsString);
        }

        // Unload the chunks
        this.emit('chunksUnloaded', chunksToUnload);

        this.lastGenerationInfo = {
            x,
            y,
            viewportWidth,
            viewportHeight
        };

    }

}